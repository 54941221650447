<template>
  <b-card>
    <pocu-header></pocu-header>
    <div id="charts-container" v-if="loadingDone && haveTableData">
      <b-row>
        <b-col cols="5">
          <b-row>
            <b-col cols="6">
                <vue-apex-charts 
                      type="pie" 
                      height="300"
                      :options="charts.buget_first_chart_apex.options" 
                      :series="charts.buget_first_chart_apex.series"/>
                <ul class="buget_first_chart_ul">
                  <li class="buget_total">
                    <span class="li_content">
                      <span class="li_title text-darken-2">
                        BUGET TOTAL
                      </span>
                      <span class="li_value">
                        {{ formatMoney(buget.total,'lei') }}
                      </span>
                    </span>
                  </li>
                  <li class="buget_cheltuit">
                    <span class="li_content">
                      <span class="li_title text-darken-2">
                        BUGET CHELTUIT
                      </span>
                      <span class="li_value">
                        {{ formatMoney(buget.cheltuit,'lei') }}
                      </span>
                    </span>
                  </li>
                  <li class="buget_disponibil">
                    <span class="li_content">
                      <span class="li_title text-darken-2">
                        BUGET DISPONIBIL
                      </span>
                      <span class="li_value">
                        {{ formatMoney(buget_disponibil,'lei') }}
                      </span>
                    </span>
                  </li>
                  <li class="cheltuieli_neeligibile">
                    <span class="li_content">
                      <span class="li_title text-darken-2">
                        CHELTUIELI NEELIGIBILE
                      </span>
                      <span class="li_value">
                        {{ formatMoney(buget.cheltuieli_neeligibile,'lei') }}
                      </span>
                    </span>
                  </li>
                </ul>
            </b-col>
            <b-col cols="6">
              <vue-apex-charts
                type="radialBar"
                height="200"
                :options="charts.buget_cheltuit.chartOptions"
                :series="charts.buget_cheltuit.series"
              />
              <p style="text-align:center">BUGET CHELTUIT</p>
              <vue-apex-charts
                type="radialBar"
                height="200"
                :options="charts.buget_disponibil.chartOptions"
                :series="charts.buget_disponibil.series"
              />
              <p style="text-align:center">BUGET DISPONIBIL</p>
              <vue-apex-charts
                type="radialBar"
                height="200"
                :options="charts.buget_neeligibil.chartOptions"
                :series="charts.buget_neeligibil.series"
              />
              <p style="text-align:center">CHELTUIELI NEELIGIBILE</p>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="7">
          <b-row>
            <b-col class="detalii_partener" cols="6" sm="4" md="4" lg="3" xl="3" v-for="partener in parteneri" :key="partener.nume">
              <div class="detalii_partener_chart">
                <vue-apex-charts
                  type="radialBar"
                  height="200"
                  :options="getPartenerChartData(partener).chartOptions"
                  :series="getPartenerChartData(partener).series"
                />
              </div>
              <div class="detalii_partener_content">
                <p class="nume_partener text-darken-2">{{ partener.nume }}</p>
                <p class="partener_subtitle text-darken-2">Buget Partener</p>
                <p class="buget_partener_total_valoare">{{ formatMoney(partener.buget_total,'lei') }}</p>
                <p class="partener_subtitle text-darken-2">Buget Cheltuit</p>
                <p class="buget_partener_cheltuit_valoare">{{ formatMoney(partener.buget_cheltuit,'lei') }}</p>
                <p class="partener_subtitle text-darken-2">Buget Disponibil</p>
                <p class="buget_partener_disponibil_valoare">{{ formatMoney(partener.buget_total - partener.buget_cheltuit,'lei') }}</p>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <div id="no_project" v-if="!currentProject">
        <p>Nu ai nici un proiect. Poți adăuga un proiect nou folosind butonul de mai sus.</p>
      </div>
      <div v-else>
        <div id="no_content" v-if="loadingDone">
          <a class="go_to_upload" @click="goToUpload()">Te rugăm să încarci documentele necesare</a>
        </div>
        <div id="loading_animation" v-else>
          <b-spinner
            variant="primary"
          /> Se încarcă
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import axios from '@axios'
import {
  BRow, BCol, BCard, BTable, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, BFormCheckboxGroup, BIconNutFill, BSpinner 
} from 'bootstrap-vue'
import { $themeColors } from '@themeConfig'
import VueApexCharts from 'vue-apexcharts'
import PocuHeader from './parts/PocuHeader.vue'
import {mapState} from 'vuex'

// colors
const chartColors = {
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
  greyBlue: '#377b95'
}

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    VueApexCharts,
    BSpinner,
    PocuHeader
  },
  data() {
    return {
      loadingDone: false,
      parteneri: {},
      buget: {},
      charts: {},
      defaultCharts:{
        buget_first_chart_apex:{
          series: [123,1,5],
          options:{
            tooltip: {
              enabled: true,
              y: {
                formatter: function(val) {
                  return new Intl.NumberFormat('ro-RO',{style:'currency',currency:'lei',minimumFractionDigits:0,maximumFractionDigits:2}).format(val)
                },
                title: {
                  formatter: function (seriesName) {
                    return seriesName
                  }
                }
              }
            },
            labels: ['Disponibil','Cheltuit','Neeligibil'],
            legend: {
              show: false
            },
            dataLabels:{
              enabled: false,
              formatter(val){
                return `${parseInt(val)}%`
              }
            },
            chart:{
              type:'pie'
            },
            colors:[$themeColors.primary,chartColors.greyBlue, chartColors.warningLightColor],
            stroke: {
              width: 0
            }
          },
        },
        buget_cheltuit:{
          series: [50],
          chartOptions: {
            colors: [chartColors.greyBlue],
            // stroke: {
            //   lineCap: 'round',
            // },
            labels: ['Buget Cheltuit'],
            plotOptions: {
              radialBar: {
                size: 185,
                hollow: {
                  size: '25%',
                },
                track: {
                  margin: 20,
                },
                dataLabels: {
                  name: {
                    show:false,
                    fontSize: '2rem',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    offsetY:8,
                    fontSize: '20px',
                    fontFamily: 'Montserrat',
                  },
                },
              },
            },
          },
        },
        buget_disponibil:{
          series: [50],
          chartOptions: {
            colors: [$themeColors.primary],
            // stroke: {
            //   lineCap: 'round',
            // },
            labels: ['Buget Cheltuit'],
            plotOptions: {
              radialBar: {
                size: 185,
                hollow: {
                  size: '25%',
                },
                track: {
                  margin: 20,
                },
                dataLabels: {
                  name: {
                    show:false,
                    fontSize: '2rem',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    offsetY:8,
                    fontSize: '20px',
                    fontFamily: 'Montserrat',
                  },
                },
              },
            },
          },
        },
        buget_neeligibil:{
          series: [50],
          chartOptions: {
            colors: [chartColors.warningLightColor],
            // stroke: {
            //   lineCap: 'round',
            // },
            labels: ['Buget Cheltuit'],
            plotOptions: {
              radialBar: {
                size: 185,
                hollow: {
                  size: '25%',
                },
                track: {
                  margin: 20,
                },
                dataLabels: {
                  name: {
                    show:false,
                    fontSize: '2rem',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    offsetY:8,
                    fontSize: '20px',
                    fontFamily: 'Montserrat',
                  },
                },
              },
            },
          },
        }
      }
    }
  },
  computed: {
    haveTableData(){
      return this.parteneri.length > 0
    },
    ...mapState('pocu',{
      currentProjectId : 'project_id',
      allProjects: 'projects'
    }),
    currentProject(){
      return this.allProjects[this.currentProjectId]
    },
    buget_disponibil :function(){
      return this.buget.total - this.buget.cheltuit
    },
    parteneriLength: function(){
      return Object.keys(this.parteneri).length > 0
    },
  },
  mounted(){

  },
  watch: {
    currentProjectId(newVal,oldVal){
      this.getExecutiaBugetara()
    }
  },
  methods: {
    maybeShowDecimals(value){
      if(Math.round(value) !== +value) {
        return +value
      }
      return Math.round(value)
    },
    goToUpload(){
      this.$router.push({name:'pocu-documentele-mele'})
    },
    resetCharts(){
      this.charts = JSON.parse(JSON.stringify(this.defaultCharts))
      this.buget = {}
      this.parteneri = {}
    },
    getPartenerChartData(partener){
      let data = {
        series: [this.maybeShowDecimals(parseFloat(partener.buget_cheltuit/(partener.buget_total/100)).toFixed(2))],
          chartOptions: {
            colors: [chartColors.greyBlue],
            // stroke: {
            //   lineCap: 'round',
            // },
            labels: ['Buget Cheltuit'],
            plotOptions: {
              radialBar: {
                size: 185,
                hollow: {
                  size: '25%',
                },
                track: {
                  margin: 20,
                },
                dataLabels: {
                  name: {
                    show:false,
                    fontSize: '2rem',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    offsetY:8,
                    fontSize: '20px',
                    fontFamily: 'Montserrat',
                  },
                },
              },
            },
          },
      }
      return data
    },
    formatMoney(number,currency){
      currency = currency || 'lei'
      return new Intl.NumberFormat('ro-RO',{style:'currency',currency:currency}).format(number)
    },
    roundValue(value){
      return Math.round((value + Number.EPSILON) * 100)/100
    },
    calculateBugetValues(buget){
      let values = {
        'Neeligibil' : buget.cheltuieli_neeligibile,
        'Disponibil': buget.total - buget.cheltuit,
        'Cheltuit': buget.cheltuit,
      }
      return values
    },
    calculateBugetPercentages(buget){
      let percent = buget.total/100;
      let percentages = {
        'Neeligibil': this.maybeShowDecimals(parseFloat(buget.cheltuieli_neeligibile / percent).toFixed(2)),
        'Cheltuit': this.maybeShowDecimals(parseFloat(buget.cheltuit/percent).toFixed(2))
      }
      percentages['Disponibil'] = this.maybeShowDecimals(parseFloat(100 - percentages['Cheltuit']).toFixed(2))
      return percentages
    },
    getExecutiaBugetara(){
      this.loadingDone = false
       this.resetCharts()
      axios
      .get('/pocu/getExecutiaBugetara',{
        params: {
          id_proiect: this.currentProjectId
        }
      })
      .then(response => {
        if (response.data.success){
          this.parteneri = response.data.parteneri
          this.buget = response.data.buget
          let second_charts_percentages = this.calculateBugetPercentages(this.buget)
          this.charts = Object.assign({}, this.defaultCharts,{
            buget_first_chart_apex: Object.assign({},this.defaultCharts.buget_first_chart_apex,{
              series:[this.buget.total-this.buget.cheltuit,this.buget.cheltuit,this.buget.cheltuieli_neeligibile]
            }),
            buget_cheltuit : Object.assign({},this.defaultCharts.buget_cheltuit,
              {
                series:[second_charts_percentages['Cheltuit']]
              }
            ),
            buget_disponibil : Object.assign({},this.defaultCharts.buget_disponibil,
              {
                series:[second_charts_percentages['Disponibil']]
              }
            ),
            buget_neeligibil : Object.assign({},this.defaultCharts.buget_neeligibil,
              {
                series:[second_charts_percentages['Neeligibil']]
              }
            )
          })
        }
        this.loadingDone = true
        // console.log(this.parteneri)
        // console.log(this.buget)
      })
    },
  },
  created(){
    if (this.currentProjectId){
      this.getExecutiaBugetara()
    }
  },
}
</script>

<style scoped>
ul.buget_first_chart_ul{
  font-size:20px;
  list-style:none;
}
ul.buget_first_chart_ul li::before{
  content: "•";
  display:inline-block; 
  width:0.5em; 
  margin-left:-0.5em;
  font-size:70px;
  line-height:50px;
}
li.buget_total::before{
  color:black;
}
li.buget_cheltuit::before{
  color:#377b95;
}
li.buget_disponibil::before{
  color:#01e8c6;
}
li.cheltuieli_neeligibile::before{
  color:#FDAC34;
}
span.li_content{
  display:inline-block;
}
li span.li_title{
  display:block;
  text-transform: uppercase;
  font-weight: 900;
  /* color:black; */
}
p.nume_partener{
  font-size: 10px;
  /* color: black; */
  font-weight: 600;
  /* text-align: justify; */
  margin-bottom: 30px;
  min-height: 65px;
}
p.partener_subtitle{
  text-transform: uppercase;
  /* color: black; */
}
p.buget_partener_total_valoare{
  font-size: 25px;
  line-height: 25px;
}
p.buget_partener_cheltuit_valoare{
  font-size: 25px;
  line-height: 25px;
  color:#377b95;
}
p.buget_partener_disponibil_valoare{
  font-size: 25px;
  line-height: 25px;
  color:#01e8c6;
  font-weight:700;
}
div.detalii_partener_content{
  /*border-right:solid 1px black;*/
}
#loading_animation{
  text-align:center;
}
#no_content{
  text-align:center;
}
#no_project{
  text-align:center;
}
.go_to_upload{
  color:#43eed5;
  text-decoration:underline;
}
</style>
<style>
.apexcharts-text.apexcharts-datalabel-value{
  fill:#373d3f;
}
body.dark-layout .apexcharts-text.apexcharts-datalabel-value{
  fill:#b4b7bd;
}
</style>